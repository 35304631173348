@import "../../util/variables";

.banner {
  position: relative;
  max-width: 1920px;
  margin: auto;

  .background {
    width: 100%;
    position: relative;
    height: 800px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    div {
      position: relative;
      width: 100%;
      display: flex;
      padding: 0.1em 1em;
      white-space: normal;
      font-size: 3rem;
      transform: rotate(-10deg);
      top: -65%;
      left: -10%;

      img {
        // height: 2em;
        // width: 2em;
        transition: 0.1s;
        padding: 0 0.1em;
        user-select: none;
        cursor: default;
        filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(1835%) hue-rotate(187deg) brightness(112%)
          contrast(88%);

        &:hover {
          filter: $svg-red-pink drop-shadow(0px 0px 40px $red-pink);
        }
      }
    }
  }
  .name-and-role {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: 0 auto;

    h1,
    h2 {
      color: white;
      font-family: $staatliches;
      font-size: calc(64px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
      margin: 0;
      text-decoration: underline;
      text-decoration-color: $red-pink;
    }
  }
}
