@import "../../util/variables";
nav {
  background-color: $black;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid $red-pink;
  padding: 0.5em 0 0.5em 0;
  position: fixed;
  z-index: 998;
  width: 100%;
}
.logo-and-links {
  flex-basis: 50%;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  .logo {
    border-right: 3px solid $red-pink;
    color: white;
    padding: 0 0.5em 0 0.5em;

    button {
      margin: 0;
      border: none;
      background: none;
      color: white;
      font-family: $staatliches;
      font-size: 1.75rem;
      letter-spacing: 1.5px;
      &:hover {
        cursor: pointer;
        color: $red-pink;
      }
    }
  }
  a {
    color: white;
    text-decoration: none;
    margin: 0 0.5em 0 0.5em;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 0.75rem;
    font-family: $staatliches;

    &:hover {
      cursor: pointer;
      color: $red-pink;
      filter: $svg-red-pink;
    }
  }

  img {
    height: 34px;
    width: 34px;
  }
}
.nav-list {
  flex-basis: 50%;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;

  li {
    margin-right: 1em;

    button {
      background-color: transparent;
      border: none;
      color: white;
      font-size: 2rem;
      font-family: $staatliches;

      &:hover {
        color: $red-pink;
        cursor: pointer;
      }
    }
  }
}
