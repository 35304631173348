@import "../../util/variables";
@import "../../util/mixins";

.skills {
  color: white;
  padding-bottom: 3em;

  .skills-container {
    width: 80%;
    margin: 0 auto;
    border-left: 2px solid $red-pink;
  }
  h2 {
    @include section-header-style;
  }

  h3 {
    font-size: 1.75rem;
    text-align: left;
    font-family: $audio-wide;
  }
  .technologies {
    padding-left: 2em;
  }

  .skill-icons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding: 0.5em 0;
      font-size: 1.1rem;
      text-transform: capitalize;
      margin-right: 1em;
      font-family: $staatliches;
      letter-spacing: 1px;
      min-width: 3em;

      &:nth-child(1) {
        padding-left: 0;
      }

      &:hover {
        img {
          transform: scale(1.25);
          filter: $svg-red-pink drop-shadow(0px 0px 40px $red-pink);
        }
      }

      img {
        height: 2em;
        width: 2em;
        filter: $svg-white;
        transition: 0.2s;
        margin-bottom: 0.4em;
      }

      span {
        text-decoration: overline;
        text-decoration-color: $red-pink;
        text-decoration-thickness: 2px;
      }
    }
  }
}
