@import "../../util/variables";
@import "../../util/mixins";

.slick-slider {
  color: white;
  width: 100%;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-arrow {
    position: absolute;
    z-index: 99;
  }

  .slick-prev {
    left: 23% !important;
    top: -40% !important;

    &::before {
      font-size: clamp(1.5vw, 3rem, 5rem);
    }
  }
  .slick-next {
    right: 25% !important;
    top: -40% !important;

    &::before {
      font-size: clamp(1.5vw, 3rem, 5rem);
    }
  }

  img {
    height: 100%;
    width: 95%;
    margin: 0 auto;

    &:hover {
      border-bottom: 2px solid $red-pink;
    }
  }

  .slick-slide {
    opacity: 50%;

    &:hover {
      cursor: pointer;
      opacity: 75%;
    }
  }
  .slick-center {
    opacity: 100%;
    margin-bottom: 3em;

    &:hover {
      cursor: default;
      opacity: 100%;
    }

    img {
      border: 3px solid $red-pink;
    }
  }
}
.project-info {
  width: 80%;
  margin: 0 auto;

  ul {
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    width: 50%;
    margin: 0 auto;

    li {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
  }

  h3 {
    font-size: 1.8rem;
    font-family: roboto;
  }

  .project-links {
    padding-bottom: 2em;
    justify-content: center;

    li {
      padding: 0 1em;
      position: relative;

      button {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-end;
        background: none;
        border: none;
        color: white;
      }

      img {
        flex-basis: 60%;
        margin-bottom: 0.1em;
        @include icon-hover-style;

        &:hover {
          cursor: pointer;
        }
      }

      .website-not-live {
        flex-basis: 60%;
        opacity: 50%;

        &:hover {
          cursor: default;
          transform: none;
          filter: $svg-white;
        }
      }

      span {
        flex-basis: 40%;
        font-size: 1rem;
        font-family: $staatliches;
        letter-spacing: 1px;
        padding-top: 0.5em;
        text-decoration: overline;
        text-decoration-color: $red-pink;
        text-decoration-thickness: 2px;
      }
    }
  }
  .tech-stack-list {
    padding-bottom: 3em;
    justify-content: center;

    li {
      font-family: $staatliches;
      letter-spacing: 1px;
      padding: 0 0.4em;

      img {
        height: 2em;
        width: 2em;
        filter: $svg-white;
        @include icon-hover-style;
        margin-bottom: 0.5em;
      }

      span {
        text-decoration: overline;
        text-decoration-color: $red-pink;
        text-decoration-thickness: 2px;
      }
    }
  }
  .project-description {
    border-left: 2px solid $red-pink;
    width: 80%;
    margin: 0 auto;
    font-family: roboto;
    font-size: 1rem;
    text-align: left;

    p {
      padding-left: 2em;
    }
  }
}

@media (max-width: 900px) {
  .project-info ul {
    width: 80%;
  }
  .slick-slider .slick-prev {
    top: -20% !important;
    left: 13% !important;
  }
  .slick-slider .slick-next {
    top: -20% !important;
    right: 15% !important;
  }
}
@media (max-width: 700px) {
  .slick-slider .slick-prev {
    top: -20% !important;
    left: 3% !important;
  }
  .slick-slider .slick-next {
    top: -20% !important;
    right: 8% !important;
  }
  .project-info .project-description {
    width: 100%;
    p {
      padding-left: 1em;
    }
  }
}
@media (max-width: 400px) {
  .slick-slider .slick-prev {
    top: -20% !important;
    left: -4% !important;
  }
  .slick-slider .slick-next {
    top: -20% !important;
    right: 2% !important;
  }
}
