@import "../../../util/variables";
.expanded-container {
  position: relative;

  button {
    background: none;
    border: none;

    img {
      height: 2em;
      width: 2em;
      padding: 1em;
      border-radius: 42%;

      &:hover {
        cursor: pointer;
        background-color: rgba(83, 83, 83, 0.397);
      }
    }
  }

  ul {
    position: absolute;
    z-index: 999;
    color: white;
    right: 25px;
    list-style-type: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    overflow: hidden;

    li {
      padding: 0.5em 0 0.5em 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid white;
      width: 100%;
      animation-duration: 1s;
      animation-name: slidein;
      background-color: black;

      &:hover {
        cursor: pointer;
        img {
          filter: $svg-red-pink;
        }
        button {
          cursor: pointer;
          color: $red-pink;
        }
      }

      button {
        padding-right: 1.5em;
        font-size: 1.2rem;
        font-family: $staatliches;
        color: white;
      }

      img {
        height: 2em;
        width: 2em;
      }
    }
  }
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 150%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
