// Colors
$black: #000000;
$white: #ffffff;
$red-pink: #d73953;
$svg-red-pink: brightness(0) saturate(100%) invert(29%) sepia(99%) saturate(1320%) hue-rotate(324deg) brightness(87%)
  contrast(92%);
$svg-white: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(313deg) brightness(102%)
  contrast(101%);

// Fonts
$staatliches: "Staatliches", sans-serif;
$audio-wide: "Audiowide", cursive;
$roboto: "Roboto", sans-serif;
