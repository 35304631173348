@import "./variables";

@mixin section-header-style {
  font-size: 3rem;
  font-family: $staatliches;
  letter-spacing: 2px;
  padding-top: 1.4em;
  margin-top: 0;

  &:before,
  &:after {
    display: inline-block;
    content: "";
    border-top: 3px solid $red-pink;
    width: 10%;
    margin: 0 1rem;
    transform: translateY(-1rem);
  }
}

@mixin icon-hover-style {
  margin-bottom: 0.1em;
  transition: 0.2s;

  &:hover {
    transform: scale(1.25);
    filter: $svg-red-pink;
  }
}
