@import "../../util/variables";
@import "../../util/mixins";
.projects {
  color: white;
  background-color: rgb(27, 27, 27);
  padding-bottom: 3rem;

  h2 {
    @include section-header-style;
  }
}

.project-links {
  img {
    height: 2em;
    width: 2em;
    filter: $svg-white;
  }
}
